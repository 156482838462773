import axios from 'axios';

import ProductCategories from '@/constants/productCategories';


class ProductRepository {
    list = [];
    page = 1;
    category = ProductCategories[0];

    async fetchProductList() {
        const { data } = await axios.get('/products/paginate', {
            headers: {
                'X-Category': this.category.key,
                'X-Page': this.page,
                'X-Limit': 100
            }
        });

        return data.results;
    }

    async initProducts() {
        this.list = await this.fetchProductList();
        return this.list;
    }

    async loadMore() {
        this.page++;
        const newList = await this.fetchProductList();
        this.list.concat(newList);
        return this.list;
    }

    async changeCategory(category) {
        this.category = category;
        this.list = await this.fetchProductList();

        return this.list;
    }

    async addWatcher(productId, email) {
        await axios.post(`/products/${productId}/watcher`, { email });
    }

    getItemById(id) {
        return this.list.find(x => x.id === id);
    }
}


export default new ProductRepository();
