import ProductRepository from './product.repository';


class BasketRepository {
    items = [];
    total = 0;
    callbacks = [];

    constructor() {
        const basket = JSON.parse(localStorage.getItem('basket') || '[]');
        if (!basket.version || basket.version < 2) localStorage.removeItem('basket');
        else this.items = basket.items;
        this.calculateTotalPrice();
    }

    calculateTotalPrice() {
        if (this.items.length > 0) {
            this.total = this.items
                .map(x => {
                    const discounted = x.price / 100 * (x.discount && x.discount > 0 ? x.discount : 0);
                    return (x.price - discounted) * x.count;
                })
                .reduce((a, b) => a + b) || 0;

            if (this.total !== 0) {
                this.total += 19.90;
            }
        } else {
            this.total = 0;
        }
    }
    
    addBasket(item) {
        const index = this.items.findIndex(x => x.id === item.id && x.size === item.size);
        const product = ProductRepository.getItemById(item.id);

        if (index > -1) {
            this.items[index].count += item.count;
        } else {
            console.log('product.discount', product.discount);
            this.items.push({
                ...item,
                name: product.name,
                image: product.files[0].preview,
                price: product.price,
                usd_price: product.usd_price,
                freeShipment: product.campaign && product.campaign.free_shipment,
                discount: product.campaign && product.campaign.discount_percent,
            });
        }

        localStorage.setItem('basket', JSON.stringify({items: this.items, version: 2}));
        this.calculateTotalPrice();

        for (const cb of this.callbacks) {
            if (typeof cb === 'function')
                cb();
        }

    }

    addWatcher(fn) {
        this.callbacks.push(fn);
    }

    removeFromBasket(item) {
        const index = this.items.findIndex(x => x.id === item.id && x.size === item.size);

        if (index > -1) {
            const arr = this.items;
            arr.splice(index, 1)
            this.items = arr;

            this.calculateTotalPrice();

            for (const cb of this.callbacks) {
                if (typeof cb === 'function')
                    cb();
            }

            localStorage.setItem('basket', JSON.stringify({items: this.items, version: 2}));
        }
    }

    clear() {
        this.items = [];
        this.total = 0;
        localStorage.clear();

        for (const cb of this.callbacks) {
            if (typeof cb === 'function')
                cb();
        }
    }
}


export default new BasketRepository();
