import { createApp } from 'vue';
import axios from 'axios';

import App from './App.vue';
import router from './router';

axios.defaults.baseURL = '//api.rotaileshop.com/';
// axios.defaults.baseURL = '//localhost:5010';

createApp(App)
    .use(router)
    .mount('#app');
