<template>
    <div>
        <LayoutHeader />
        <router-view 
            :openModal="openModal"
            :openVerificationModal="openVerificationModal"
        />
        <LayoutFooter />
        <ProductDetailModal 
            v-if="showModal"
            :product="product"
            :closeModal="closeModal"
            :openVerificationModal="openVerificationModal"
        />
        <VerificationModal
            v-if="verificationModal"
            :title="verificationTitle"
            :closeModal="closeVerificationModal"
            :message="verificationMessage"
            :isBasketAction="isBasketAction"
        />
        <UnsubscribeModal
            v-if="unsubscribeModal"
            :closeModal="closeUnsubscribeModal"
        />
        <div class="modal-backdrop in" v-if="showModal || verificationModal || unsubscribeModal" />
    </div>
</template>

<script>
    import { ref } from 'vue';
    import axios from 'axios';

    import LayoutHeader from '@/components/layout/Header.vue';
    import LayoutFooter from '@/components/layout/Footer.vue';
    import ProductDetailModal from '@/components/product/DetailModal.vue';
    import VerificationModal from '@/components/VerificationModal.vue';
    import UnsubscribeModal from '@/components/UnsubscribeModal.vue';
    import BasketRepository from '@/repositories/basket';

    export default {
        name: 'home',

        components: {
            LayoutHeader,
            LayoutFooter,
            ProductDetailModal,
            VerificationModal,
            UnsubscribeModal,
        },
        
        setup() {
            let showModal = ref(false);
            let verificationModal = ref(false);
            let unsubscribeModal = ref(false);
            let product = ref({});
            let verificationTitle = ref('');
            let verificationMessage = ref('');
            let verificationCloseCb = ref(undefined);
            let isBasketAction = ref(false);

            const closeModal = () => {
                product.value = {};
                showModal.value = false;
                document.removeEventListener('click', closeModal);
                document.body.className = '';
            }

            const openModal = (item) => {
                product.value = item;
                showModal.value = true;
                document.addEventListener('click', closeModal);
                document.body.className = 'overflowhidden';
            };

            const closeVerificationModal = () => {
                verificationTitle.value = '';
                verificationMessage.value = '';
                verificationModal.value = false;
                isBasketAction.value = false;

                if (verificationCloseCb.value) verificationCloseCb.value();

                verificationCloseCb.value = null;
                document.removeEventListener('click', closeVerificationModal);
                document.body.className = '';
            }

            const closeUnsubscribeModal = () => {
                unsubscribeModal.value = false;

                document.removeEventListener('click', closeUnsubscribeModal);
                document.body.className = '';
            };

            const openVerificationModal = (title, message, isAction, cb) => {
                verificationTitle.value = title;
                verificationMessage.value = message;
                verificationModal.value = true;
                isBasketAction.value = isAction;
                if (cb) {
                    verificationCloseCb.value = cb;
                }
                document.addEventListener('click', closeVerificationModal);
                document.body.className = 'overflowhidden';
            };

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('origin') && urlParams.get('origin').length > 0) {
                const org = localStorage.getItem('origin') ? JSON.parse(localStorage.getItem('origin')) : null;
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                if (!org || (org.date && new Date(org.date) > yesterday)) {
                    localStorage.setItem('origin', JSON.stringify({ origin: urlParams.get('origin'), date: new Date() }));
                }

                axios.post('/origins', { name: urlParams.get('origin') });
            }

            if (urlParams.get('status') && urlParams.get('status').toLowerCase() === 'ok') {
                window.history.pushState({}, 'Rotaile Shop', '/');
                BasketRepository.clear();
                openVerificationModal('', '<p>Siparişiniz başarıyla tamamlandı.</p>', false);
            } else if (urlParams.get('status') && urlParams.get('status').toLowerCase() === 'error') {
                window.history.pushState({}, 'Rotaile Shop', '/');
                var message = urlParams.get('message');
                if (!message) message = 'Siparişiniz alınırken bir hata oluştu. Lütfen kart bilgilerinizi ve limitinizi kontrol edip tekrar deneyiniz.';
                alert(message);
            } else if (typeof urlParams.get('unsubscribe') === 'string') {
                window.history.pushState({}, 'Rotaile Shop', '/');
                unsubscribeModal.value = true;
                document.addEventListener('click', closeUnsubscribeModal);
                document.body.className = 'overflowhidden';
            }

            return {
                verificationTitle,
                verificationMessage,
                showModal,
                product,
                isBasketAction,
                verificationModal,
                unsubscribeModal,
                verificationCloseCb,
                closeModal,
                openModal,
                closeVerificationModal,
                openVerificationModal,
                closeUnsubscribeModal
            }
        },

        mounted: () => {
        }
    };
</script>
