<template>
    <div class='page-section section pt-150 pb-80'>
        <div class='container'>
            <div class='row'>
                <div class='checkout-form'>
                    <div class='col-lg-6 col-md-6 mb-40'>
                        <form action='#'>
                            <h3>SİPARİŞ DETAYLARı</h3>
                            <div class='row'>
                                <div class='col-xs-12 mb-30'>
                                    <label for='b_country'>Ülke<span class='required'>*</span></label>
                                    <select id='b_country' v-model='country' @change='changeCountry($event)'>
                                        <option v-for='item in countries' v-bind:key='item.name' :value='item.key'>
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class='col-xs-12 mb-30'>
                                    <label for='b_city'>Şehir<span class='required'>*</span></label>
                                    <select id='b_city' v-model='city' v-if="country === 'TR'">
                                        <option value=''>Şehir seçiniz</option>
                                        <option v-for='item in cities' v-bind:key='item' :value='item'>
                                            {{ item }}
                                        </option>
                                    </select>
                                    <input id='b_l_city' type='text' v-model='city' v-if="country !== 'TR'" />
                                </div>
                                <div class='col-sm-6 col-xs-12 mb-30'>
                                    <label for='b_f_name'>İlçe<span class='required'>*</span></label>
                                    <input id='b_f_name' type='text' v-model='district' />
                                </div>
                                <div class='col-sm-6 col-xs-12 mb-30'>
                                    <label for='b_f_name'>İsim<span class='required'>*</span></label>
                                    <input id='b_f_name' type='text' v-model='firstname' />
                                </div>
                                <div class='col-sm-6 col-xs-12 mb-30'>
                                    <label for='b_l_name'>Soyisim<span class='required'>*</span></label>
                                    <input id='b_l_name' type='text' v-model='lastname' />
                                </div>
                                <div class='col-xs-12 mb-30'>
                                    <label for='b_c_name'>E-Posta Adresi<span class='required'>*</span></label>
                                    <input id='b_c_name' type='email' v-model='email' />
                                </div>
                                <div class='col-xs-12 mb-30'>
                                    <label for='b_phone'>Telefon Numarası<span class='required'>*</span></label>
                                    <input id='b_phone' type='text' v-model='phone' />
                                </div>
                                <div class='col-xs-12 mb-30'>
                                    <label>Adres<span class='required'>*</span></label>
                                    <input
                                        type='text'
                                        v-model='address'
                                    />
                                </div>
                            </div>
                            <div class='order-notes'>
                                <label for='message'>Mesaj</label>
                                <textarea
                                    id='message'
                                    placeholder=''
                                    v-model='message'
                                ></textarea>
                            </div>
                        </form>
                    </div>
                    <div class='col-lg-6 col-md-6 col-xs-12 mb-40'>
                        <h3>ÖDEME</h3>
                        <div class='row'>
                            <div class='col-sm-12 col-xs-12 mb-20'>
                                <h3 v-if="country === 'TR'">
                                    <input id='payment_cash' class='radio-btn' type='radio' v-model='paymentMethod'
                                           value='cash'>
                                    <label for='payment_cash' class='radio-label'>Kapıda Ödeme</label>
                                </h3>
                                <h3>
                                    <input id='payment_card' class='radio-btn' type='radio' v-model='paymentMethod'
                                           value='card'>
                                    <label for='payment_card' class='radio-label'>Online Ödeme</label>
                                </h3>
                            </div>
                        </div>
                        <div class='row' v-if="paymentMethod === 'card' || country !== 'TR'">
                            <div class='col-sm-6 col-xs-12 mb-30'>
                                <label for='b_f_card_holder'>Kart Sahibi<span class='required'>*</span></label>
                                <input id='b_f_card_holder' type='text' v-model='cardHolderName' />
                            </div>
                            <div class='col-sm-6 col-xs-12 mb-30'>
                                <label for='b_card_number'>Kart Numarası<span class='required'>*</span></label>
                                <input id='b_card_number' type='text' v-model='cardNumber'
                                       @keypress='onlyNumber($event)' />
                            </div>
                            <div class='col-xs-12 mb-'>
                                <label for='b_last_use'>Son Kullanma Tarihi</label>
                            </div>
                            <div class='col-xs-6 mb-20'>
                                <select id='b_month' v-model='expireMonth'>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='11'>11</option>
                                    <option value='12'>12</option>
                                </select>
                            </div>
                            <div class='col-xs-6 mb-20'>
                                <select id='b_year' v-model='expireYear'>
                                    <option v-for='year in years' v-bind:key='year' :value='year'>
                                        {{ year }}
                                    </option>
                                </select>
                            </div>
                            <div class='col-xs-12 mb-30'>
                                <label>CCV (Kartın arkasındaki son 3 hane)<span class='required'>*</span></label>
                                <input
                                    type='text'
                                    placeholder='CCV'
                                    v-model='ccv'
                                />
                            </div>
                        </div>

                        <div class='order-wrapper'>
                            <h3>SİPARİŞİNİZ</h3>
                            <div class='order-table table-responsive mb-30'>
                                <table style='width: 100%;'>
                                    <thead>
                                    <tr>
                                        <th class='product-name'>
                                            ÜRÜN
                                        </th>
                                        <th class='product-total'>Tutar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for='item in products' v-bind:key='item.id'>
                                        <td class='product-name'>
                                            {{ item.name }} {{
                                                item.size && item.size !== '-' ? (
                                                    `- ${item.size.toUpperCase()}`
                                                ) : '' }}
                                            <strong class='product-qty'>× {{ item.count }}</strong>
                                        </td>
                                        <td class='product-total' v-html='getPriceText(item)'>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th>Kargo</th>
                                        <td v-html='getShipmentPrice()'></td>
                                    </tr>
                                    <tr>
                                        <th>Toplam</th>
                                        <td><strong>{{ getTotalPrice() }}</strong></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class='payment-method'>
                                <div class='order-button clearfix'>
                                    <button style='float: right' type='button' @click.stop='post()'>
                                        <img v-if='loading' src='img/loading.gif' />
                                        {{ loading == false ? 'SİPARİŞİ TAMAMLA' : '' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

import countries from '@/constants/countries';
import cities from '@/constants/cities';
import BasketRepository from '@/repositories/basket';
import router from '@/router';


function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhone(phone) {
    return phone.length >= 9;
}

function validateExpireDate(expiryMonth, expiryYear) {
    return !(
        !expiryMonth ||
        !(expiryMonth > 0 && expiryMonth < 13) ||
        !expiryYear ||
        expiryYear < (new Date()).getFullYear() ||
        (expiryYear == (new Date()).getFullYear() && expiryMonth < ((new Date()).getMonth() + 1))
    );
}


export default {
    name: 'Checkout',
    props: ['openModal', 'openVerificationModal'],
    components: {},

    data() {
        const years = [];
        const currentYear = (new Date()).getFullYear();
        for (let year = currentYear; year <= currentYear + 15; year++)
            years.push(year);

        return {
            years,
            country: 'TR',
            city: '',
            district: '',
            cities,
            countries,
            firstname: '',
            lastname: '',
            email: '',
            expireYear: (new Date()).getFullYear(),
            expireMonth: (new Date()).getMonth() + 1,
            address: '',
            phone: '',
            cardNumber: '',
            cardHolderName: '',
            ccv: '',
            message: '',
            paymentMethod: 'cash',
            loading: false
        };
    },

    methods: {
        getShipmentPrice() {
            const shipmentPrice = this.freeShipment ? '0.00' : '19.90';
            if (this.country === 'TR') {
                return `<span class='linethrough'>₺24.90</span> ₺${shipmentPrice}`;
            }

            return `<span class='linethrough'>$24.90</span> $${shipmentPrice}`;
        },

        changeCountry(event) {
            if (event.target.value !== 'TR') {
                this.paymentMethod = 'card';
            }
        },

        getPriceText(item) {
            let symbol = '₺';
            let price = item.price;

            if (this.country !== 'TR') {
                symbol = '$';
                price = item.usd_price;
            }

            if (item.discount > 0) {
                const discounted = price / 100 * item.discount;
                return `<span class='amount'><span class='linethrough'>${symbol}${(item.count * price).toFixed(2)}</span> ${symbol}${((price - discounted) * item.count).toFixed(2)}</span>`;
            }

            return `<span class='amount'>${symbol}${(item.count * price).toFixed(2)}</span>`;
        },

        getTotalPrice() {
            let symbol = '₺';
            const total = this.products
                .map(x => {
                    const priceKey = this.country === 'TR' ? 'price' : 'usd_price';
                    const price = x[priceKey];
                    const discounted = price / 100 * (x.discount || 0);
                    return (price - discounted) * x.count;
                })
                .reduce((a, b) => a + b, 0);
            if (this.country !== 'TR') {
                symbol = '$';
            }

            return `${symbol}${(total + (this.freeShipment ? 0 : 19.90)).toFixed(2)}`;
        },

        onlyNumber(evt) {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode;
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
                return evt.preventDefault();

            const cardNum = this.cardNumber.split(' ').join('');
            if (cardNum.length >= 16) {
                return evt.preventDefault();
            }

            if (cardNum.length % 4 === 0 && cardNum.length < 16) {
                this.cardNumber += ' ';
            }
            return true;
        },

        async post() {
            if (this.loading === true) {
                return;
            }
            let error = '';
            const isEmailValid = validateEmail(this.email);
            const isPhoneValid = validatePhone(this.phone);
            const isExpireDateValid = this.paymentMethod === 'card' ? validateExpireDate(this.expireMonth, this.expireYear) : true;
            const cardNumber = this.cardNumber.split(' ').join('');

            if (!isEmailValid) error += 'E-Posta adresiniz geçersiz!\n';
            if (!isPhoneValid) error += 'Telefon numaranız geçersiz!\n';
            if (!isExpireDateValid) error += 'Kartınızın son kullanma tarihi geçersiz!\n';
            if (this.paymentMethod === 'card' && cardNumber.length != 16) error += 'Kart numarası eksik!\n';
            if (this.paymentMethod === 'card' && this.cardHolderName.length < 3) error += 'Kart sahibi hatalı!\n';
            if (!this.city || this.city.length === 0) error += 'Şehir bilgisi geçersiz!\n';
            if (!this.district || this.district.length === 0) error += 'İlçe bilgisi giriniz\n';

            if (error.length > 0) {
                return alert(error);
            }

            this.loading = true;
            const data = {
                email: this.email,
                phone: this.phone,
                expireMonth: this.expireMonth,
                expireYear: this.expireYear,
                firstname: this.firstname,
                lastname: this.lastname,
                address: this.address,
                cardNumber,
                cardHolderName: this.cardHolderName,
                ccv: this.ccv,
                paymentMethod: this.paymentMethod,
                country: this.country,
                products: BasketRepository.items,
                city: this.city,
                message: this.message,
                district: this.district
            };

            if (localStorage.getItem('origin') && localStorage.getItem('origin').length) {
                const item = JSON.parse(localStorage.getItem('origin'));
                data.origin = item.origin;
            }

            try {
                // const instance = axios.create({ baseURL: 'http://localhost:5000/' });
                const { data: responseData, status } = await axios({
                    url: '/orders',
                    method: 'post',
                    data
                });

                if (responseData.redirectUrl) {
                    window.location = responseData.redirectUrl;
                } else if (status > 199 && status < 300) {
                    this.loading = false;
                    const redirect = () => {
                        BasketRepository.clear();
                        router.push('/');
                    };
                    // TODO: ADDPAYMENT
                    this.openVerificationModal(
                        '',
                        'Siparişiniz başarıyla tamamlandı.<br />',
                        false,
                        redirect
                    );
                } else {
                    this.loading = false;
                    alert('Sipariş esnasında bir sorun oluştu.');
                }
            } catch (error) {
                this.loading = false;
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error === 'Bad Request'
                ) {
                    alert(error.response.data.message);
                } else {
                    alert('Sipariş esnasında bir sorun oluştu.');
                }
                return;
            }
        }
    },

    setup() {
        let products = ref(BasketRepository.items);
        let total = ref(BasketRepository.total);
        let freeShipment = ref(BasketRepository.items.findIndex(x => x.freeShipment) > -1);
        const basketWatcher = () => {
            if (BasketRepository.items.length === 0) {
                router.push('/');
            }
            products.value = [...BasketRepository.items];
            total.value = new Number(BasketRepository.total);
            freeShipment.value = BasketRepository.items.findIndex(x => x.freeShipment) > -1;
        };
        BasketRepository.addWatcher(basketWatcher);

        return {
            products,
            total,
            freeShipment
        };
    }
};
</script>
