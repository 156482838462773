<template>
    <transition name="modal">
        <div id="quickViewModal" class="modal fade in">
            <div class="container" @click.stop>
                <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12"></div>
                <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="modal-content verification-modal row">
                        <div class="modal-body">
                            <button
                                class="close"
                                data-dismiss="modal"
                                @click="closeModal"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1 v-if="title && title.length > 0">{{ title }}</h1>
                            <img src="img/check.png" alt="">
                            <p v-html="message"></p>

                            <div class="action-container">
                                <button 
                                    v-if="isBasketAction"
                                    @click="closeModal"
                                    class="action-keep-goin"
                                > ALIŞVERİŞE DEVAM ET </button>
                                <button class="action-button" v-if="isBasketAction" @click="goToCheckout"> SİPARİŞİ TAMAMLA </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import router from '@/router';

    export default {
        name: 'verification-modal',

        props: ['closeModal', 'title', 'message', 'isBasketAction'],

        data() {
            return {
                count: 1
            };
        },

        methods: {
            goToCheckout() {
                router.push('/checkout');
                this.closeModal();
            }
        }
    }
</script>
