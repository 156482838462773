<template>
  <div class="page-section contact-page-section section pt-150 pb-90">
    <div class="container">
        <div class="row mb-20 contact-container">
            <!-- Contact Form -->
            <div class="contact-form col-md-6 col-xs-12">
                <h3>Bizimle iletişime geç</h3>
                <form id="contact-form">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12 mb-20">
                            <input type="text" name="name" placeholder="Ad Soyad" v-model="name">
                        </div>
                        <div class="col-sm-6 col-xs-12 mb-20">
                            <input type="text" name="email" placeholder="E-Posta" v-model="email">
                        </div>
                        <div class="col-xs-12 mb-20">
                            <textarea name="message" placeholder="Mesajınız" v-model="message"></textarea>
                        </div>
                        <div class="col-xs-12"><input type="button" @click="sendMessage" value="GÖNDER"></div>
                    </div>
                </form>
                <p class="form-messege"></p>
            </div>
        </div>
        <!-- Contact Info -->
		<div class="row">
		    <div class="contact-info col-md-4 text-center mb-30">
		        <a href="tel:+905443872020">Telefon: +90 544 387 2020</a>
		    </div>
		    <div class="contact-info col-md-4 text-center mb-30">
		        <a href="mailto:iletisim@rotaileshop.com">E-Posta: iletisim@rotaileshop.com</a>
		    </div>
		    <!-- <div class="contact-info col-md-4 text-center mb-30">
		        <p>Address: house 09,Road 3,Dhaka</p>
		    </div> -->
		</div>
    </div>
</div>
</template>

<script>
    import 'vue';
    import axios from 'axios';

    function validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    export default {
        name: 'product-detail-modal',

        data() {
            return {
                email: '',
                name: '',
                message: '',
            };
        },
            
        methods: {
            sendMessage() {
                const mailIsValid = validateEmail(this.email);
                if (!mailIsValid) {
                    return alert('E-posta adresinizini kontrol ediniz.');
                }

                axios.post(
                    '/contact',
                    { email: this.email, name: this.name, message: this.message }
                ).then(() => {
                    alert('Mesajınız başarıyla iletildi.');
                    this.email = '';
                    this.name = '';
                    this.message = '';
                }).catch((error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error === 'Bad Request'
                    ) {
                        let message = error.response.data.message;
                        if (typeof message === 'object') {
                            message = message.join('\n* ');
                            message = '* ' + message;
                        }
                        alert(message);
                    } else {
                        alert('Mesaj gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin');
                    }
                });
            },
        },
    }
</script>
