<template>
    <transition name="modal">
        <div id="quickViewModal" class="modal fade in">
            <div class="container" @click.stop>
                <div class="modal-content row">
                    <div class="modal-body">
                        <button
                            class="close"
                            data-dismiss="modal"
                            @click="closeModal"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <!-- QuickView Product Images -->
                        <div class="col-md-5 col-sm-6 col-xs-12">
                            <!-- Tab panes -->
                            <div class="tab-content mb-10">
                                <div class="pro-large-img tab-pane" style="display:block;">
                                    <img :src="`//rotaileshop.com/images/products/${product.files[selectedImage].big}?v=2`" :alt="product.name" />

                                    <div class="product-thumbnail-container">
                                        <img
                                            :src="`//rotaileshop.com/images/products/${item.preview}?v=2`"
                                            :alt="product.name"
                                            v-for="(item, index) in product.files"
                                            v-bind:key="item.id"
                                            v-on:click="changeImage(index)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- QuickView Product Details -->
                        <div class="col-md-7 col-sm-6 col-xs-12">
                            <div class="product-details section">
                                <!-- Title -->
                                <h1 class="title">{{ product.name }}</h1>
                                <!-- Price Ratting -->
                                <div class="price-ratting section">
                                    <!-- Price -->
                                    <span class="price float-left">
                                        <span class="new">
                                            ₺ {{ getPriceText(product.price) }}
                                        </span>
                                    </span>
                                </div>
                                <!-- Short Description -->
                                <div class="short-desc section">
                                    <h5 class="pd-sub-title">Açıklama</h5>
                                    <div v-html="clearDescription(product.description)"></div>
                                </div>
                                <!-- Product Size -->
                                <div class="product-size section" v-if="sizesIsVisible">
                                    <h5 class="pd-sub-title">Beden</h5>
                                    <button
                                        v-for="size in product.sizes"
                                        v-bind:key="size.name"
                                        :class="{ 'active': selectedSize === size.name, disable: size.count === 0 }"
                                        @click.stop="selectSize(size)"
                                    >
                                        {{ size.name }}
                                    </button>
                                </div>
                                <!-- Quantity Cart -->
                                <div class="quantity-cart section" v-if="totalCount > 0">
                                    <div class="product-quantity">
                                        <input type="text" v-model="count" />
                                    </div>
                                    <button class="add-to-cart" @click.stop="addToBasket()">
                                        sepete ekle
                                    </button>
                                </div>
                                <div class="quantity-cart section" v-if="totalCount === 0">
                                    <input id="b_card_number" type="text" v-model="email" placeholder="e-posta" />
                                    <button class="add-to-cart" @click.stop="addWatcher()">
                                        GELİNCE HABER VER
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { ref } from 'vue';
    import slugify from 'slugify';

    import BasketRepository from '@/repositories/basket';
    import ProductRepository from '@/repositories/product.repository';


    function validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    export default {
        name: 'product-detail-modal',

        props: ['closeModal', 'product', 'openVerificationModal'],

        data() {
            return {
                count: 1,
                totalCount: this.product.sizes.length > 1 ? (
                    this.product.sizes.map(x => x.count).reduce((a, b) => a + b)
                ) : (
                    this.product.sizes.length === 1 ? this.product.sizes[0].count : 0
                ),
                email: '',
                sizesIsVisible: this.product.sizes.filter(size => size.name !== '-').length > 0,
                selectedImage: 0,
                // sizesIsVisible: this.product.sizes.length > 0
            };
        },
            
        methods: {
            getPriceText(price) {
                return price.toFixed(2);
            },

            clearDescription(description) {
                const index = description.indexOf('<p data-f-id="pbf"');
                if (index > -1)
                    return description.substring(0, index);
                return description;
            },

            changeImage(index) {
                this.selectedImage = index;
            },

            addToBasket() {
                if (this.count < 1) {
                    alert('Sepete eklemek için en az bir ürün seçmelisiniz!');
                } else if (this.product.sizes.length > 0 && (!this.selectedSize || this.selectedSize.length === 0)) { 
                    alert('Lütfen beden seçiniz!');
                } else {
                    BasketRepository.addBasket({
                        id: this.product.id,
                        size: this.selectedSize || '',
                        count: this.count
                    });
                    this.closeModal();
                    this.openVerificationModal('', 'Ürün başarıyla sepetinize eklendi.', true);
                }
            },

            keyPress: (event) => {
                if (event.keyCode == 27) {
                    this.closeModal();
                }
            },

            async addWatcher() {
                if (!validateEmail(this.email)) {
                    alert('Lütfen geçerli bir e-posta adresi giriniz!');
                    return;
                }

                await ProductRepository.addWatcher(this.product.id, this.email);
                this.email = '';
                alert('Ürün tekrar stoklara geldiğinde e-posta ile bilgilendirileceksiniz.');
            }
        },

        setup() {
            let selectedSize = ref(null);            
            const selectSize = (size) => {
                if (size.count > 0)
                    selectedSize.value = size.name;
            };

            return {
                selectedSize,
                selectSize,
            };
        },

        mounted() {
            if (this.product.sizes.length === 1) {
                this.selectSize(this.product.sizes[0]);
            }
            const slug = slugify(
                this.product.name,
                {
                    replacement: '-',
                    lower: true,
                    remove: /\./
                }
            );
            window.history.pushState({}, 'Rotaile Shop', `/${slug}`);


            window.addEventListener('keydown', (event) => {
                if (event.keyCode == 27) {
                    this.closeModal();
                }
            })
        },

        unmounted: function () {
            window.removeEventListener('keydown', (event) => {
                if (event.keyCode == 27) {
                    this.closeModal();
                }
            });
            window.history.pushState({}, 'Rotaile Shop', '/');
        }
    }
</script>
