<template>
    <div class="product-item text-center">
        <div class="product-img">
            <span class="image cursor" @click.stop="openModal(product)">
                <img :src="`//rotaileshop.com/images/products/${product.files[0].preview}?v=2`" :alt="product.name" />
            </span>
            <div v-if="totalCount === 0" class="product-campaign">
                TÜKENDİ
            </div>
            <div v-if="totalCount > 0 && product.campaign" class="product-campaign">
                {{ product.campaign.text }}
            </div>
        </div>
        <div class="product-info text-left">
            <h5 class="title">
                <span @click.stop="openModal(product)" class="cursor">
                    {{ product.name }}
                    <span v-if="product.subtitle && product.subtitle.length > 0" class="description">
                        ({{ product.subtitle }})
                    </span>
                </span>
            </h5>
            <div class="price-ratting fix">
                <span class="price float-left">
                    <span class="new">₺{{ getPriceText(product.price) }}</span>
                </span>
            </div>
        </div>
    </div>
</template>


<script>
    import BasketRepository from '@/repositories/basket';


    export default {
        name: 'product-home-page',
        
        components: {},
        
        props: ['product', 'openModal'],

        data() {
            return {
                totalCount: this.product.sizes.length > 1 ? (
                    this.product.sizes.map(x => x.count).reduce((a, b) => a + b)
                ) : (
                    this.product.sizes.length === 1 ? this.product.sizes[0].count : 0
                ),
            };
        },
        
        methods: {
            getPriceText(price) {
                return price.toFixed(2);
            },

            addBasket() {
                BasketRepository.addBasket({
                    id: this.product,
                    // size: 
                });
            }
        },
    };
</script>
