<template>
    <transition name="modal">
        <div id="unsubscribeModal" class="modal fade in">
            <div class="container" @click.stop>
                <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12"></div>
                <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="modal-content verification-modal row">
                        <div class="modal-body">
                            <button
                                class="close"
                                data-dismiss="modal"
                                @click="close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>Mail listesinden çıkartıldınız</h1>
                            <img src="img/check.png" alt="">
                            <p>Başarı ile mail listesinden çıkartıldınız</p>

                            <div class="action-container">
                                <button class="action-button" @click="close"> TAMAM </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import router from '@/router';

export default {
    name: 'unsubscribe-modal',

    props: ['closeModal'],

    data() {
        return {
            count: 1
        };
    },

    methods: {
        close() {
            router.push('/');
            this.closeModal();
        }
    }
}
</script>
