<template>
    <!-- FOOTER BOTTOM SECTION START -->
    <div class="footer-bottom-section section">
        <div class="container">
            <div class="row">
                <!-- Copyright -->
                <div class="copyright text-left col-sm-6 col-xs-8">
                    <p>
                        Powered by
                        <a href="#">Rotaile</a>
                    </p>
                </div>
                <!-- Payment Method -->
                <div class="footer-menu text-right col-sm-6 col-xs-4">
                    <router-link to="/contact">İletişim</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'layout-footer',
        components: {},
        methods: {},
    };
</script>
