<template>
    <div class="product-section section pt-150 pb-120">
        <div class="container">
            <div class="row filter-row">
                <!-- Isotop Product Filter -->
                <div class="isotope-product-filter col-xs-12">
                    <span
                        class="button"
                        v-for="item in productCategories"
                        @click="changeCategory(item)"
                        v-bind:key="item"
                        v-bind:class="{
                            active: item.key === activeCategory.key,
                        }"
                    >
                        {{ item.title }}
                    </span>
                </div>
                <!-- Product Filter Toggle -->
                <!-- <div class="col-xs-4">
                    <button class="product-filter-toggle" v-on:click="toggleFilter()">filter</button>
                </div> -->
            </div>

            <!-- Product Filter Wrapper -->
            <div class="row">
                <div class="col-xs-12">
                    <div
                        class="product-filter-wrapper"
                        :style="{ display: filterStatus ? 'block' : 'none' }"
                    >
                        <!-- Product Filter -->
                        <div
                            class="
                                product-filter
                                col-md-3 col-sm-6 col-xs-12
                                mb-30
                            "
                        >
                            <h5>Sort by</h5>
                            <ul class="sort-by">
                                <li><a href="#">Default</a></li>
                                <li><a href="#">Popularity</a></li>
                                <li><a href="#">Average rating</a></li>
                                <li><a href="#">Newness</a></li>
                                <li><a href="#">Price: Low to High</a></li>
                                <li><a href="#">Price: High to Low</a></li>
                            </ul>
                        </div>
                        <!-- Product Filter -->
                        <div
                            class="
                                product-filter
                                col-md-3 col-sm-6 col-xs-12
                                mb-30
                            "
                        >
                            <h5>color filters</h5>
                            <ul class="color-filter">
                                <li>
                                    <a href="#"
                                        ><i
                                            style="background-color: #000000"
                                        ></i
                                        >Black</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        ><i
                                            style="background-color: #663300"
                                        ></i
                                        >Brown</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        ><i
                                            style="background-color: #ff6801"
                                        ></i
                                        >Orange</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        ><i
                                            style="background-color: #ff0000"
                                        ></i
                                        >red</a
                                    >
                                </li>
                                <li>
                                    <a href="#"
                                        ><i
                                            style="background-color: #ffee00"
                                        ></i
                                        >Yellow</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <!-- Product Filter -->
                        <div
                            class="
                                product-filter
                                col-md-3 col-sm-6 col-xs-12
                                mb-30
                            "
                        >
                            <h5>product tags</h5>
                            <div class="product-tags">
                                <a href="#">New</a>, <a href="#">brand</a>,
                                <a href="#">black</a>, <a href="#">white</a>,
                                <a href="#">chire</a>, <a href="#">table</a>,
                                <a href="#">Lorem</a>, <a href="#">ipsum</a>,
                                <a href="#">dolor</a>, <a href="#">sit</a>,
                                <a href="#">amet</a>
                            </div>
                        </div>
                        <!-- Product Filter -->
                        <div
                            class="
                                product-filter
                                col-md-3 col-sm-6 col-xs-12
                                mb-30
                            "
                        >
                            <h5>Filter by price</h5>
                            <div id="price-range"></div>
                            <div class="price-values">
                                <span>Price:</span>
                                <input type="text" class="price-amount" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="isotope-grid row"
                v-bind:key="x"
                v-for="x in products"
            >
                <div
                    class="
                        isotope-item
                        chair
                        home-decor
                        col-lg-3 col-md-4 col-sm-6 col-xs-6
                        mb-50
                    "
                    v-bind:key="item.id"
                    v-for="item in x"
                >
                    <ProductHomePage :product="item" :openModal="openModal" />
                </div>
            </div>

            <div class="row">
                <!-- <div class="text-center col-xs-12 mt-30">
                    <a
                        href="#"
                        @click="loadMore()"
                        class="btn load-more-product"
                        >load more</a
                    >
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import slugify from 'slugify';
    
    import ProductRepository from '@/repositories/product.repository';
    
    import ProductCategories from '@/constants/productCategories';

    import ProductHomePage from './HomePageDetail.vue';

    const groupProducts = (arr) => {
        const products = [];
        const screenSize = window.innerWidth;
        let perItem = 4;

        if (screenSize < 992) perItem = 2;
        else if (screenSize < 1200) perItem = 3;

        for (let index = 0; index < arr.length; index ++) {
            const fi = Math.floor(index / perItem);
            if (!products[fi]) products[fi] = [];
            products[fi].push(arr[index]);
        }

        return products;
    }

    export default {
        name: 'home-page-product-list-container',

        props: ['openModal', 'param'],

        components: {
            ProductHomePage,
        },

        data() {
            return {
                productCategories: ProductCategories,
                filterStatus: false,
                paramCategoryIndex: ProductCategories.findIndex(x => x.key === this.$route.params.param),
            };
        },

        methods: {
            toggleFilter() {
                this.filterStatus = !this.filterStatus;
            },
        },

        setup() {
            let products = ref([]);
            let activeCategory = ref({});

            const getProducts = async () => {
                products.value = groupProducts(await ProductRepository.initProducts());
                activeCategory.value = ProductRepository.category;
            };

            const changeCategory = async (category) => {
                products.value = groupProducts(await ProductRepository.changeCategory(category));
                activeCategory.value = ProductRepository.category;
                if (category.key !== 'tumu') {
                    window.history.pushState({}, 'Rotaile Shop', `/${category.key}`);
                } else {
                    window.history.pushState({}, 'Rotaile Shop', '/');
                }
            };

            const loadMore = async () => {
                products.value = groupProducts(await ProductRepository.loadMore());
                activeCategory.value = ProductRepository.category;
            };

            return {
                products,
                activeCategory,
                getProducts,
                changeCategory,
                loadMore,
            }
        },

        mounted() {
            this
                .changeCategory(ProductCategories[this.paramCategoryIndex > - 1 ? this.paramCategoryIndex : 0])
                .then(() => {
                    if (this.paramCategoryIndex === -1) {
                        const productIndex = this.products
                            .flat()
                            .findIndex(x => {
                                const slug = slugify(
                                    x.name,
                                    {
                                        replacement: '-',
                                        lower: true,
                                        remove: /\./
                                    }
                                );
                                return slug === this.$route.params.param;
                            });
                        if (productIndex > -1) {
                            this.openModal(this.products.flat()[productIndex]);
                        }
                    }
                });
        }
    };
</script>
