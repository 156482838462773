<template>
    <header class="header-section section sticker">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <!-- logo -->
                    <div class="header-logo float-left">
                        <router-link :to="{ name: 'RotaileShop', force: true }">
                            <img src="img/logo-2.png" alt="main logo" />
                        </router-link>
                    </div>
                    <!-- header-search & total-cart -->
                    <div class="float-right menu-bar-bar">
                        <div class="header-option-btns float-right">
                            <!-- Header Cart -->
                            <div class="header-cart float-left">
                                <!-- Cart Toggle -->
                                <span
                                    class="cart-toggle cursor"
                                    href="#"
                                    data-toggle="dropdown"
                                    @click.stop="toggleBasket()"
                                >
                                    <i class="pe-7s-cart"></i>
                                    <span v-if="items.length > 0">{{ items.length }}</span>
                                </span>
                                <!-- Mini Cart Brief -->
                                <div
                                    class="
                                        mini-cart-brief
                                        dropdown-menu
                                        text-left
                                    "
                                    @click.stop=""
                                    :style="{ visibility: basketStatus ? 'visible' : 'hidden', opacity: basketStatus ? 1 : 0}"
                                >
                                    <!-- Cart Products -->
                                    <div class="all-cart-product clearfix">
                                        <div
                                            class="single-cart clearfix"
                                            v-for="item in items"
                                            v-bind:key="item.id"
                                        >
                                            <div class="cart-image">
                                                <img
                                                    :src="`//rotaileshop.com/images/products/${item.image}`"
                                                    :alt="item.name"
                                                />
                                            </div>
                                            <div class="cart-info">
                                                <h5>
                                                    {{ item.name }}
                                                </h5>
                                                <p>{{ item.count }} x <span v-html="getItemPriceText(item)"></span></p>
                                                <span
                                                    href="#"
                                                    class="cart-delete cursor"
                                                    title="Remove this item"
                                                    @click.stop="removeItemFromBasket(item)"
                                                >
                                                    <i class="pe-7s-trash"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Cart Total -->
                                    <div class="cart-totals">
                                        <h5>Toplam <span>₺{{ getPriceText(total && total > 0 ? total - 19.90 : total) }}</span></h5>
                                    </div>
                                    <!-- Cart Button -->
                                    <div class="cart-bottom clearfix">
                                        <div v-if="items.length === 0">Sepetinizde ürün bulunmamaktadır.</div>
                                        <router-link v-if="items.length > 0" to="/checkout" @click="toggleBasket()">alışverişi tamamla</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav class="main-menu menu-right float-right">
                            <ul>
                                <li class="active">
                                    <router-link :to="{ name: 'RotaileShop', force: true }">Anasayfa</router-link>
                                </li>
                                <li>
                                    <router-link to="/contact">İLETİŞİM</router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { ref } from 'vue';

    import BasketRepository from '@/repositories/basket'

    export default {
        name: 'layout-header',
        components: {},

        methods: {
            getPriceText(price) {
                return price.toFixed(2);
            },

            getItemPriceText(item) {
                let symbol = '₺';
                let price = item.price;

                if (item.discount > 0) {
                    const discounted = price / 100 * item.discount;
                    return `<span class="linethrough">${symbol}${(item.count * price).toFixed(2)}</span> ${symbol}${((price - discounted) * item.count).toFixed(2)}`;
                }

                return `${symbol}${(item.count * price).toFixed(2)}</span>`;
            },
        },

        setup() {
            let basketStatus = ref(false);
            let mobileMenuStatus = ref(false);
            let items = ref(BasketRepository.items);
            let total = ref(BasketRepository.total);

            const toggleBasket = () => {
                basketStatus.value = !basketStatus.value;
                if (basketStatus.value === true) {
                    document.addEventListener('click', toggleBasket);
                } else {
                    document.removeEventListener('click', toggleBasket);
                }
            };

            const toggleMobileMenu = () => {
                mobileMenuStatus.value = !mobileMenuStatus.value;
                if (mobileMenuStatus.value === true) {
                    document.addEventListener('click', toggleMobileMenu);
                } else {
                    document.removeEventListener('click', toggleMobileMenu);
                }
            };

            const removeItemFromBasket = (item) => {
                BasketRepository.removeFromBasket(item);
            };

            const reloadBasket = () => {
                items.value = [...BasketRepository.items];
                total.value = BasketRepository.total;
            };

            BasketRepository.addWatcher(reloadBasket);
            
            return {
                items,
                total,
                basketStatus,
                mobileMenuStatus,
                toggleBasket,
                toggleMobileMenu,
                removeItemFromBasket,
            };
        }
    };
</script>
