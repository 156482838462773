<template>
    <ProductHomePageContainer :openModal="openModal" />
</template>

<script>
    import ProductHomePageContainer from '@/components/product/HomePageContainer.vue';

    export default {
        name: 'Home',
        props: ['openModal'],
        components: {
            ProductHomePageContainer,
        },
    };
</script>
