import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Contact from '../views/Contact.vue';
import Checkout from '../views/Checkout.vue';

const routes = [
    {
        path: '/contact',
        name: 'RotaileShop İletişim',
        component: Contact,
    },
    {
        path: '/checkout',
        name: 'RotaileShop Sipariş',
        component: Checkout,
    },
    {
        path: '/',
        name: 'RotaileShop',
        component: Home,
    },
    {
        path: '/:param',
        name: 'RotaileShop Param',
        component: Home,
    },
    // {
    //     path: '/:catchAll(.*)',
    //     name: 'RotaileShop',
    //     component: Home,
    //     beforeEnter: (to, from, next) => {
    //         if (to === from)
    //             window.location.reload()
    //         return next()
    //     }
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from) => {
    setTimeout(() => {
        window.scrollTo(0, 0);
        if (from.name && to.path === from.path) {
            window.location.reload()
        }
    }, 100);
});

export default router;
